import * as React from "react"
import { observer } from "mobx-react-lite";
import Button from '@atlaskit/button';
import { navigate } from "gatsby";


const BottomBar = () => {

    const bottomLinks = [
        {
            text: "© 2022 Hold My Coffee Ltd",
            button: false,
            link: "/",
        },
        {
            text: "Privacy Policy",
            button: true,
            link: "/privacy",
        },
        {
            text: "Refunds Policy",
            button: true,
            link: "/refunds",
        },
        {
            text: "Terms of Service",
            button: true,
            link: "/terms",
        },
        {
            text: "Support: support@autocompress.atlassian.net",
            button: false,
            link: "",
        },
    ];

    return(
        <div style={{ position: "relative", margin: "auto", marginTop: 100, paddingTop: 20, width: "100vw", backgroundColor: "#F4F5F7", display: "flex", flexDirection: "column", alignItems: "center", justifyItems: "center", alignContent: "center", justifyContent: "center" }}>
            <div style={{ height: 25 }}></div>
            <span style={{ margin: "auto", color: "#42526E" }}> Other apps by us: </span>
            <span style={{ margin: "auto", color: "#42526E" }}>
                <a href="https://www.pseudoface.com" style={{ color: "#42526E", textDecoration: "none" }}>
                    Pseudoface | AI Filter for Faceless Creators
                </a>
            </span>
            <div style={{ height: 25 }}></div>
            <div style={{ margin: "auto", display: "flex", flexDirection: "row", alignItems: "center", justifyItems: "center", alignContent: "center", justifyContent: "center" }}>
                {bottomLinks.map((link) => {
                    return <Button appearance="link" style={{ marginLeft: 5, marginRight: 5 }} onClick={() => navigate(link.link)}>
                        <span style={{ color: "#42526E" }}> {link.text} </span>
                    </Button>
                })}
            </div>
            <div style={{ height: 25 }}></div>
        </div>
    );

}

export default observer(BottomBar);
