import * as React from "react"
import { useCallback, useEffect, useState } from "react";
import axios from 'axios';
import NavBar from "../components/navbar";
import "../styles.css";
import Button from '@atlaskit/button';
import { Script } from "gatsby";
import Card from '@material-ui/core/Card';
import {
  Content,
  PageLayout,
  TopNavigation,
} from '@atlaskit/page-layout';
import mainStore from "../stores/mainStore";
import authService from "../services/authService";
import { observer } from "mobx-react-lite";
import { IAccessData } from "../models/accessData";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import Select from '@atlaskit/select';
import Tooltip from '@atlaskit/tooltip';
import QuestionCircleIcon from '@atlaskit/icon/glyph/question-circle';
import workspaceService from "../services/workspaceService";
import { IWorkspace } from "../models/workspace";
import Radium from 'radium';
import BottomBar from "../components/bottomBar";



var $ = require( "jquery" );

const Pricing = () => {

  const isBrowser = () => typeof window !== "undefined";
  let accessData: IAccessData = mainStore.accessData;
  const user = mainStore.user;
  const [loading, setLoading] = useState<boolean>(true);
  const [multipleWorkspacesModal, setMultipleWorkspacesModal] = useState<boolean>(false);
  const Paddle = isBrowser() && window.Paddle;

  if (Paddle) {
    if (!mainStore.isProduction) Paddle.Environment.set("sandbox");
    Paddle.Setup({ vendor: Number(mainStore.paddleVendorId) });
  }

  useEffect(() => {
    const initialize = async () => {
      if (!isBrowser()) return;
      if (!accessData.accessToken) {
        let params = new URLSearchParams(document.location.search);
        let code = params.get("code");
        const previousCode = localStorage.getItem("previous_code");
        if (code) {
          if (code!==previousCode) {
            localStorage.setItem("previous_code", code);
            if (!await authService.getAccessTokenFromAuth(code)) {
              setLoading(false);
              return;
            }
          } else {
            if (!await authService.getAccessTokenFromRefresh()) {
              setLoading(false);
              return;
            }
          }
        } else {
					if (!await authService.getAccessTokenFromRefresh()) {
            setLoading(false);
						return;
					}
        }
      }

      if (!await authService.getAccessTokenFromRefresh()) {
        setLoading(false);
        return;
      }


      await authService.getUserFromBitbucket();
      getWorkspaces();
      
      // const planId = localStorage.getItem("selected_plan");
      // const clientKey = localStorage.getItem("clientKey");

      // if (planId && clientKey) {
      //   localStorage.removeItem("selected_plan");
      //   localStorage.removeItem("clientKey");
      //   Paddle.Checkout.open({
      //     product: parseInt(planId),
      //     customData: {
      //       "clientKey": clientKey,
      //     }
      //   });
      // }
      setLoading(false);
    }
    initialize();    
  }, []);



  const [width, setWidth] = useState(isBrowser() ? window.innerWidth / 1.1 : "90%");

  useEffect(() => {
    const handleWindowResize = () => setWidth(isBrowser() ? window.innerWidth / 1.1 : "90%");
    isBrowser() && window.addEventListener("resize", handleWindowResize);

    if (isBrowser())
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    const handleWindowResize = () => setWidth(isBrowser() ? window.innerWidth / 1.1 : "90%");
    isBrowser() && window.addEventListener("resize", handleWindowResize);

    if (isBrowser())
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [isBrowser]);


  const launchInstallationUrl = () => {
    isBrowser()
      && window.open(
        mainStore.installationUrl,
        "_self");
  }

  const openCheckout = async (planId: number) => {
    setSelectedPlanId(planId);

    if (!mainStore.user) {
      localStorage.setItem("selected_plan", planId.toString());
      launchInstallationUrl();
      return;
    }

    const ownedClientKeys: string[] = await workspaceService.getClientKeysFromOwnedWorkspaces();
    
    if (ownedClientKeys.length > 1) {
      setMultipleWorkspacesModal(true);
    } else if (ownedClientKeys.length == 1) {
      Paddle.Checkout.open({
        product: planId,
        customData: {
          "clientKey": ownedClientKeys[0],
        }
      });
    } else if (ownedClientKeys.length == 0) {
      localStorage.setItem("selected_plan", planId.toString());
      launchInstallationUrl();
    }
  }

  // const getLocalizedPrices = async () => {
  //   $.ajax({
  //     url: 'https://checkout.paddle.com/api/2.0/prices',
  //     dataType: 'jsonp',
  //     data: {
  //       product_ids: "43736, 43737"
  //     },
  //     success: function (data: any) {
  //       console.log(data);
  //     }
  //   });
  // }

  const closeInitModal = useCallback(() => setMultipleWorkspacesModal(false), []);
  const submitInitModal = () => {
    setMultipleWorkspacesModal(false);
    Paddle.Checkout.open({
      product: selectedPlanId,
      customData: {
        "clientKey": selectedWorkspace.value,
      }
    });
  }
  const [buttonText, setButtonText] = useState("Install & Try for Free");

  const [selectedPlanId, setSelectedPlanId] = useState<number | undefined>(undefined);
  const [selectedWorkspace, setSelectedWorkspace] = useState<any | undefined>(mainStore.workspaces.length > 0 ? mainStore.workspaces[0] : undefined);
  const [ownedWorkspaces, setOwnedWorkspaces] = useState<IWorkspace[]>(mainStore.workspaces);

  useEffect(() => {
    setOwnedWorkspaces(mainStore.workspaces);
    setSelectedWorkspace(mainStore.workspaces[0]);
  }, [mainStore.workspaces]);

  const handleWorkspaceSelection = (newValue: any) => {
    console.log("handleWorkspaceSelection");
    console.log(newValue);
    if (newValue.value) {
      setSelectedWorkspace(newValue);
    }
  };

  useEffect(() => {
    // getLocalizedPrices();
    if (mainStore.user) {
      setButtonText("Install & Try Free");
    }
  }, []);

  const getWorkspaces = async () => {
    const repos = await workspaceService.getRepositoriesFromBitbucket();
    await workspaceService.getWorkspacesFromAutocompress(repos as any[]);
  };

  return (
    <>
      <Script src="https://cdn.paddle.com/paddle/paddle.js"/>

      <PageLayout>
        <TopNavigation
            testId="topNavigation"
            id="product-navigation"
            skipLinkTitle="Product Navigation"
            height={60}
            isFixed={false}
        >
          <NavBar/>
        </TopNavigation>
        <Content testId="content">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{
              position: "relative", 
              display: "flex",
              margin: "auto",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: 1500
            }}>
                <h1 style={{ textAlign:"center", minWidth: 300, width: "60%", color: "#253858", fontWeight: "bold", fontSize: 50 }}>Start with a free 14-day trial</h1>
                <span style={{ textAlign:"center", minWidth: 250, width: "60%", color: "#253858", fontSize: 16, fontWeight: "normal", paddingLeft: 10, paddingRight: 10 }}>
                  Plans are based on the number of private repositories being compressed, while compression is always free for public repositories. Each plan covers only one workspace (ie. if you need to compress repos in 2 workspaces, you will need 2 subscriptions). <br/>
                </span>
                <div style={{
                  paddingTop: 30,
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: 'wrap',
                  width: "100%",
                  alignItems: "start",
                  justifyContent: "center"
                  }}>
                    {mainStore.paddlePlans.map((plan) => {
                        return <Card elevation={2} style={{ width: "20%", minWidth: 200, margin: 10}}>
                          <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "start" }}>
                            <img src={plan.img} style={{ paddingTop: 25, margin: "auto", width: "50%"}}></img>
                            <span style={{ color: "#4A6798", fontWeight: "normal", fontSize: 35, textAlign: "center" }}>{`${plan.name}`}</span>
                            <div style={{ height: 10 }}></div>
                            <span style={{ color: "#4A6798", fontSize: 16, fontWeight: "normal", textAlign: "center" }}>
                              {`Up to ${plan.repos} repositories`}<br/>
                              {`Unlimited users`}<br/>
                            </span>
                            <span style={{ color: "#4A6798", margin: 20, fontSize: 20, fontWeight: "bold", textAlign: "center" }}>
                            {`${plan.price}/month`}
                            </span>
                            <Button isDisabled={loading} appearance="primary" onClick={() => openCheckout(plan.id)}
                            style={{ margin: 20,  width: "80%" }}
                            >{buttonText}</Button>
                            <div style={{ height: 10 }}></div>
                          </div>
                        </Card>
                  })}
                </div>
            <div style={{ height: 30 }}></div>
            <span style={{ color: "#4A6798", fontSize: 16, fontWeight: "normal", textAlign: "center" }}>
              * Prices on USD. Taxes may apply.<br/>Secure payment via Paddle.
            </span>
            <div style={{ height: 60 }}></div>
            <div style={{
                  marginRight: 20,
                  marginLeft: 20,
                  padding: 20,
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: 'wrap',
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#E3EFFF",
                  maxWidth: 600,
                  borderRadius: 10
                  }}>
                  <span style={{ color: "#4A6798", fontWeight: "bold", fontSize: 18, textAlign: "center" }}>{"30-Day Money-Back Guarantee"}</span>
                  <div style={{ height: 15 }}></div>
                  <span style={{ color: "#4A6798", fontSize: 16, fontWeight: "normal", textAlign: "center" }}>
                    It's risk free! If you're not happy with your purchase for whatever reason, we will refund, no questions asked.<br/><br/>
                    Contact us: 💌 support@autocompress.atlassian.net
                  </span>
                </div>
          </div>
          <BottomBar/>
        </div>
        <ModalTransition>
                {multipleWorkspacesModal && (
                <Modal onClose={closeInitModal} shouldScrollInViewport={true}>
                    <ModalHeader>
                    <ModalTitle>Which workspace would you like to {mainStore.user ? "upgrade" : "subscribe"}?</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                    Autocompress is installed in more than one of your workspaces, please select which of them should be {mainStore.user ? "upgraded" : "subscribed"}:
                    <div style={{ paddingTop: 25, display: "flex", flexDirection: "column", maxWidth: "60%" }}>
                      <label style={{ paddingBottom: 5,fontWeight: "bold", fontSize: 12, color: "rgb(107,119,140)" }} htmlFor="workspaceSelect">Workspaces: </label>
                      <div style={{ cursor: "pointer", display: "flex", flexDirection: "row", alignItems: "center", justifyItems: "center" }}>
                        <div style={{ flex: 1 }}>
                          <Select
                              defaultValue={selectedWorkspace?.name}
                              onChange={handleWorkspaceSelection}
                              label="workspaceSelect"
                              appearance="default"
                              options={ownedWorkspaces?.map(workspace => ({
                                  label: workspace.name,
                                  value: workspace.clientKey
                                }))
                              }
                          />
                        </div>
                        <div style={{ width: 5 }}></div>
                          <Tooltip content={"Choose whether to sacrifice image quality to get smaller sizes"}>
                            <QuestionCircleIcon label="help" primaryColor="rgb(107,119,140)"/>
                          </Tooltip>
                      </div>
                    </div>
                    </ModalBody>
                    <ModalFooter>
                    <Button appearance="subtle" onClick={closeInitModal}>
                        Cancel
                    </Button>
                    <Button appearance="primary" onClick={submitInitModal} autoFocus>
                        {mainStore.user ? "Upgrade" : "Subscribe"}
                    </Button>
                    </ModalFooter>
                </Modal>
                )}
        </ModalTransition>
        </Content>
      </PageLayout>
    </>
      
  );
}

export default observer(Radium(Pricing));

declare global {
  interface Window {
      Paddle:any;
  }
}